@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');

:root {
  --primary-color: #047aed;
  --secondary-color: #1c3fa8;
  --dark-color: #002240;
  --light-color: #f4f4f4;
  --success-color: #5cb85c;
  --error-color: #d9534f;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {  
  font-family: 'Lato', sans-serif;
  color: #333;
  line-height: 1.6;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

h1,
h2 {
    font-weight: 300;
    line-height: 1.2;
    margin: 10px 0;
}

p {
  margin: 10px 0;
}

img {
  width: 100%;
}

code, pre {
  background: #333;
  color: #fff;
  padding: 10px;
}

.hidden {
  visibility: hidden;
  height: 0;
}

/* Navigation */

.navbar {
  background-color: var(--primary-color);
  color: #fff;
  height: 70px;
}

.navbar ul {
  display: flex;
}

.navbar .flex {
  justify-content: space-between;
}

.navbar a {
  color: #fff;
  padding: 10px;
  margin: 0 5px;
}

.navbar a:hover {
  border-bottom: 2px #fff solid;
}

.navbar .flex {
  justify-content: space-between;
}

/* Seizure */
.seizure {
  background-color: var(--primary-color);
  padding-top: 50px;
  color: #fff;
}

.seizure h1 {
  font-size: 40px;
}

.seizure ul {
  font-size: 20px;
}

/* Stats */
.stats {
  padding-top: 100px;
  /* animation: slideInFromBottom 1s ease-in; */
}

.stats-heading {
  max-width: 500px;
  margin: auto;
}

.stats .grid h3 {
  font-size: 35px;
}

.stats .grid p {
  font-size: 20px;
  font-weight: bold;
}

/* Animations */
@keyframes slideInFromLeft {
  0% {
      transform: translateX(-100%);
  }

  100% {
      transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
      transform: translateX(100%);
  }

  100% {
      transform: translateX(0);
  }
}

@keyframes slideInFromTop {
  0% {
      transform: translateY(-100%);
  }

  100% {
      transform: translateX(0);
  }
}

@keyframes slideInFromBottom {
  0% {
      transform: translateY(100%);
  }

  100% {
      transform: translateX(0);
  }
}

/* todos */
.grid-todo {
  grid-template-columns: 1fr 5fr 3fr 1fr;
}

.todo-header {
  font-weight: bold;
}

.add-todo-form {
  margin-top: 20px;
  margin-bottom: 20px;
}

.add-todo-form input {
  height: 40px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 14px;
}


/* .add-form {
  margin-bottom: 40px;
}

.form-control {
  margin: 20px 0;
}

.form-control label {
  display: block;
}

.form-control input {
  width: 100%;
  height: 40px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 17px;
} */
